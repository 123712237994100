h1, .h1 {
  @apply text-3xl;
  @apply leading-snug;

  &.headline {
    @apply text-4xl;
  }
}

@screen md {
  h1, .h1 {
    @apply text-4xl;

    &.headline {
      @apply text-5xl;
      @apply font-light;
    }
  }
}

h2, .h2 {
  @apply text-2xl;
  @apply leading-tight;
}

@screen md {
  h2, .h2 {
    @apply text-3xl;
  }
}

h3, .h3 {
  @apply text-2xl;
}

h4, .h4 {
  @apply text-xl;
}

h5, .h5, h6, .h6 {
  @apply text-base;
}
