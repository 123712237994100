@import "../abstracts/colors";

/* To be moved to v2/typography (eventually) */
a {
  @apply text-red-500;

  &:hover {
    @apply text-red-400;
  }
}

#position_list_search_container,
#faa,
[data-tab="fixedage"],
#navigation_report_modal_content,
[data-tab="vvq_sub_heatmap"]{

  & input,
  & select {
    padding: 2px 4px;
    @apply pl-2;
  }

  .pagination {

    & input,
    & select {
      padding: 0.25rem 0.5rem;
    }
  }
}

.vv_at_pane{
  & select{
    padding-right: 1.75rem;
  }
}

#dcf_assumptions,
[data-tab="dcf"]{
  & select{
    margin-left: 0px;
    padding-right: 1.75rem;
    padding-left: 0.5rem;
    background-position: right 0 center;
  }

  #dcfv_TR {
    padding-right: 0.5rem;
  }
}

[data-tabs="trade"]{
  & select {
    padding-right: 2rem;
  }
}

#responsiveChart{
  & select {
    padding-right: 2rem;
    margin-left: 0rem;
    margin-right: 0rem;
  }
}

#trade-tabs-vue{
  & select {
    margin-left: 0rem;
    margin-right: 0rem;
    padding-right: 2rem;
  }
}

#linear-assumptions {
  .custom_value_input {
    margin-bottom: 10px;
  }
}

#forecast_commentary,
#forecast_commentary_historical,
#viamar_commentary {
  p {
    @apply .py-2;
  }
}

#popup p {
  margin-bottom: 10px;
}

#popup-footer p {
  margin-bottom: 0;
}

.ui-datepicker .ui-datepicker-buttonpane button {
  font-size: 0.85rem;
  background-color: $lighter-grey;
}

img.ui-datepicker-trigger {
  display: inline-block;
}

.vv_at_toolpane .section_body {
  box-sizing: content-box;
}

input,
textarea {
  &::placeholder {
    color: $lighter-dark-grey;
    opacity: 1;
  }
}

#remember_me+label[for="remember_me"] {
  &::before {
    height: 18px;
    width: 18px;
  }

  &::after {
    top: 3px;
    left: 3px
  }
}

/*
  Offsets anchored sections from the fixed header.
  This is temporary until the page content is not behind the fixed header.
*/
.section-offset::before {
  display: block;
  content: "";
  height: 120px;
  margin-top: -120px;
  visibility: hidden;
}

.minimal-section-offset::before {
  display: block;
  content: "";
  height: 80px;
  margin-top: -120px;
  visibility: hidden;
}

// Workaround for using viewport height in mobile browsers
// @see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
.h-screen-fix {
  height: var(--100vh);
}

// Style for adding an overlay background behind modals (ModalBase).
.overlay {
  background-color: rgba(0, 0, 0, .25);
}
