.japanese {
  font-family: "Open Sans", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.chinese {
  font-family: "Open Sans", Helvetica, Arial, "Microsoft YaHei New", "Microsoft Yahei", "微软雅黑", 宋体, SimSun, STXihei, "华文细黑", sans-serif;
}

.korean {
  font-family: "Open Sans", Helvetica, Arial, Malgun Gothic, "돋움", Dotum, sans-serif;
}
