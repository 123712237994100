input,
input:focus {
  // Removes the defined background colour around autocompleted input from Firefox.
  filter: none;

  // Removes the defined background colour around autocompleted input from webkit-based browsers.
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px #F6F6F6 inset;

    &::first-line {
      @apply text-sm;
    }
  }
}

//This is to replace default focus outline added by browser
  a, button, [role="button"] {
  outline: none;

  &:focus-visible {
    box-shadow: inset 0 0 0 2px #21a3a3;
  }

  &:focus {
    outline: none;
  }
};

input, textarea, select {
  outline: none;

  &:focus-visible, &:focus {
    box-shadow: inset 0 0 0 2px #21A3A3;
  }
}

.focus-indicator,
.focus\:focus-indicator:focus, {
  box-shadow: inset 0 0 0 2px #21a3a3;
}
