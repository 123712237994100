@charset "UTF-8";
@tailwind base;
@tailwind components;
@tailwind utilities;
h1, .h1 {
  @apply text-3xl;
  @apply leading-snug; }
  h1.headline, .h1.headline {
    @apply text-4xl; }

@screen md {
  h1, .h1 {
    @apply text-4xl; }
    h1.headline, .h1.headline {
      @apply text-5xl;
      @apply font-light; } }

h2, .h2 {
  @apply text-2xl;
  @apply leading-tight; }

@screen md {
  h2, .h2 {
    @apply text-3xl; } }

h3, .h3 {
  @apply text-2xl; }

h4, .h4 {
  @apply text-xl; }

h5, .h5, h6, .h6 {
  @apply text-base; }

/**
 * @see https://jira.vvit.co.uk/browse/VIT-7000
 * @see https://confluence.vvit.co.uk/display/DEV/Web+Guidelines+2020#WebGuidelines2020-ColourPalette
 */
/**
 * Colours from the VesselsValue brand guidelines
 * @see https://confluence.vvit.co.uk/display/DEV/Web+Guidelines+2020#WebGuidelines2020-ColourPaletteBrand
 */
/**
 * All permutations of grey currently used on the site
 * @see https://confluence.vvit.co.uk/display/DEV/Web+Guidelines+2020#WebGuidelines2020-ColourPaletteGreys
 */
/**
 * Colour used for notifications on the website
 * @see https://confluence.vvit.co.uk/display/DEV/Web+Guidelines+2020#WebGuidelines2020-ColourPaletteNotifications
 */
/**
 * Valuation-specific colours
 * @see https://confluence.vvit.co.uk/display/DEV/Web+Guidelines+2020#WebGuidelines2020-ColourPaletteValuations
 */
/**
 * Misc.
 * @see https://confluence.vvit.co.uk/display/DEV/Web+Guidelines+2020#WebGuidelines2020-ColourPaletteOther
 */
/* [IE11] */
.bg-gradient-red {
  background: linear-gradient(to bottom, #B50000 0%, #F41C1C 100%); }

input,
input:focus {
  filter: none; }
  input:-webkit-autofill,
  input:focus:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px #F6F6F6 inset; }
    input:-webkit-autofill::first-line,
    input:focus:-webkit-autofill::first-line {
      @apply text-sm; }

a, button, [role="button"] {
  outline: none; }
  a:focus-visible, button:focus-visible, [role="button"]:focus-visible {
    box-shadow: inset 0 0 0 2px #21a3a3; }
  a:focus, button:focus, [role="button"]:focus {
    outline: none; }

input, textarea, select {
  outline: none; }
  input:focus-visible, input:focus, textarea:focus-visible, textarea:focus, select:focus-visible, select:focus {
    box-shadow: inset 0 0 0 2px #21A3A3; }

.focus-indicator,
.focus\:focus-indicator:focus {
  box-shadow: inset 0 0 0 2px #21a3a3; }

.japanese {
  font-family: "Open Sans", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif; }

.chinese {
  font-family: "Open Sans", Helvetica, Arial, "Microsoft YaHei New", "Microsoft Yahei", "微软雅黑", 宋体, SimSun, STXihei, "华文细黑", sans-serif; }

.korean {
  font-family: "Open Sans", Helvetica, Arial, Malgun Gothic, "돋움", Dotum, sans-serif; }

/**
 * @see https://jira.vvit.co.uk/browse/VIT-7000
 * @see https://confluence.vvit.co.uk/display/DEV/Web+Guidelines+2020#WebGuidelines2020-ColourPalette
 */
/**
 * Colours from the VesselsValue brand guidelines
 * @see https://confluence.vvit.co.uk/display/DEV/Web+Guidelines+2020#WebGuidelines2020-ColourPaletteBrand
 */
/**
 * All permutations of grey currently used on the site
 * @see https://confluence.vvit.co.uk/display/DEV/Web+Guidelines+2020#WebGuidelines2020-ColourPaletteGreys
 */
/**
 * Colour used for notifications on the website
 * @see https://confluence.vvit.co.uk/display/DEV/Web+Guidelines+2020#WebGuidelines2020-ColourPaletteNotifications
 */
/**
 * Valuation-specific colours
 * @see https://confluence.vvit.co.uk/display/DEV/Web+Guidelines+2020#WebGuidelines2020-ColourPaletteValuations
 */
/**
 * Misc.
 * @see https://confluence.vvit.co.uk/display/DEV/Web+Guidelines+2020#WebGuidelines2020-ColourPaletteOther
 */
/* To be moved to v2/typography (eventually) */
a {
  @apply text-red-500; }
  a:hover {
    @apply text-red-400; }

#position_list_search_container input,
#position_list_search_container select,
#faa input,
#faa select,
[data-tab="fixedage"] input,
[data-tab="fixedage"] select,
#navigation_report_modal_content input,
#navigation_report_modal_content select,
[data-tab="vvq_sub_heatmap"] input,
[data-tab="vvq_sub_heatmap"] select {
  padding: 2px 4px;
  @apply pl-2; }

#position_list_search_container .pagination input,
#position_list_search_container .pagination select,
#faa .pagination input,
#faa .pagination select,
[data-tab="fixedage"] .pagination input,
[data-tab="fixedage"] .pagination select,
#navigation_report_modal_content .pagination input,
#navigation_report_modal_content .pagination select,
[data-tab="vvq_sub_heatmap"] .pagination input,
[data-tab="vvq_sub_heatmap"] .pagination select {
  padding: 0.25rem 0.5rem; }

.vv_at_pane select {
  padding-right: 1.75rem; }

#dcf_assumptions select,
[data-tab="dcf"] select {
  margin-left: 0px;
  padding-right: 1.75rem;
  padding-left: 0.5rem;
  background-position: right 0 center; }

#dcf_assumptions #dcfv_TR,
[data-tab="dcf"] #dcfv_TR {
  padding-right: 0.5rem; }

[data-tabs="trade"] select {
  padding-right: 2rem; }

#responsiveChart select {
  padding-right: 2rem;
  margin-left: 0rem;
  margin-right: 0rem; }

#trade-tabs-vue select {
  margin-left: 0rem;
  margin-right: 0rem;
  padding-right: 2rem; }

#linear-assumptions .custom_value_input {
  margin-bottom: 10px; }

#forecast_commentary p,
#forecast_commentary_historical p,
#viamar_commentary p {
  @apply .py-2; }

#popup p {
  margin-bottom: 10px; }

#popup-footer p {
  margin-bottom: 0; }

.ui-datepicker .ui-datepicker-buttonpane button {
  font-size: 0.85rem;
  background-color: #F7F7F7; }

img.ui-datepicker-trigger {
  display: inline-block; }

.vv_at_toolpane .section_body {
  box-sizing: content-box; }

input::placeholder,
textarea::placeholder {
  color: #898A84;
  opacity: 1; }

#remember_me + label[for="remember_me"]::before {
  height: 18px;
  width: 18px; }

#remember_me + label[for="remember_me"]::after {
  top: 3px;
  left: 3px; }

/*
  Offsets anchored sections from the fixed header.
  This is temporary until the page content is not behind the fixed header.
*/
.section-offset::before {
  display: block;
  content: "";
  height: 120px;
  margin-top: -120px;
  visibility: hidden; }

.minimal-section-offset::before {
  display: block;
  content: "";
  height: 80px;
  margin-top: -120px;
  visibility: hidden; }

.h-screen-fix {
  height: var(100vh); }

.overlay {
  background-color: rgba(0, 0, 0, 0.25); }
