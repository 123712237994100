@tailwind base;

@tailwind components;

@tailwind utilities;

@import "./base/typography";

@import './base/backgrounds';

@import './forms/inputs';

@import "./fonts/language";

@import "fixes";
